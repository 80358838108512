import React from 'react'

const Divider = () => <div className="bg-muted-100 h-px w-auto flex-grow" />

const Footer = () => {
  return (
    <footer className="text-center py-8 mt-8 container">
      <div className="flex pb-4 justify-center items-center">
        <Divider />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          className="w-6 h-auto mx-4 text-primary"
        >
          <path
            d="M15.16,5.17l-.09,0-.18,0-.21,0a22,22,0,0,0-4.37.31l-.21,0S9.23,3.15,8.71,1.57a2,2,0,0,0-.59-1L8,.51a.47.47,0,0,0-.2,0,.44.44,0,0,0-.15,0c-.26.11-.39.43-.51.87l-.07.26,0,.06C6.68,3.1,6.14,5.12,6,5.7A37,37,0,0,0,1.2,6c-.58.13-.94.44-1,.85a.81.81,0,0,0,0,.19.73.73,0,0,0,.35.51A2.05,2.05,0,0,0,1,7.88C2.49,8.41,4.33,9.2,5,9.44l0,.11c-.23.61-1.36,3.88-1.21,5,0,0,0,.09,0,.13l0,.12a1.27,1.27,0,0,0,.08.24,1.22,1.22,0,0,0,.08.16l.08.11a.58.58,0,0,0,.45.23c.79,0,3.19-3.23,4-4.16h0a.11.11,0,0,1,.15,0l.23.22c.61.58,2,1.85,3.07,2.72a3.41,3.41,0,0,0,1.51.87c.35-.05.53-.44.53-.93,0-1.21-1.63-4.4-2-5.2L15.4,6.59a.86.86,0,0,0,.44-.81h0C15.82,5.48,15.55,5.28,15.16,5.17Z"
            fill="currentColor"
          />
        </svg>
        <Divider />
      </div>
      &copy; Stephy Miehle
    </footer>
  )
}

export default Footer
