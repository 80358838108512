import React from 'react'
import PropTypes from 'prop-types'
import Meta from '../Meta'
import Transition from '../Transition'
import Header from '../Header'
import Footer from '../Footer'

import '../../sass/layout.scss'

const LayoutWrapper = ({ location, children }) => (
  <>
    <Meta location={location}>
      <html lang="en" />
    </Meta>
    <Header />

    <Transition location={location}>{children}</Transition>

    <Footer />
  </>
)

LayoutWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutWrapper
