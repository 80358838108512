import React from 'react'
import { Link } from 'gatsby'

const NavLink = ({ to = '/', children, ...rest }) => {
  return (
    <Link
      to={to}
      className="block md:inline-block mt-2 md:mt-0 text-primary-800 hover:text-primary-600 md:mr-4"
      activeClassName="text-primary-600"
      {...rest}
    >
      {children}
    </Link>
  )
}

export default NavLink
