import React, { useState } from 'react'
import { Link } from 'gatsby'
import Headroom from 'react-headroom'
import NavLink from './NavLink'

const breakpoint = 'md'

function Header() {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <Headroom as="header">
      <nav className="w-full flex items-center justify-between flex-wrap bg-primary-50 p-6">
        <div className="flex items-center flex-no-shrink mr-6">
          <Link
            to="/"
            className="text-primary hover:text-primary-300 font-semibold text-xl tracking-tight"
          >
            Stephy Miehle
          </Link>
        </div>
        <div className={`block ${breakpoint}:hidden`}>
          <button
            className="flex items-center px-3 py-2 border rounded text-primary-300 border-primary-300 hover:text-primary-700 hover:border-primary-700"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path
                d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
        <div
          className={`w-full block flex-grow ${breakpoint}:flex ${breakpoint}:items-center ${breakpoint}:w-auto`}
        >
          <div
            className={`${
              isExpanded ? `block` : `hidden`
            } ${breakpoint}:flex items-center text-sm w-full justify-between`}
          >
            <div>
              <NavLink to="/about">About</NavLink>
              <NavLink to="/contact">Contact</NavLink>
              <NavLink to="/resume">Resume</NavLink>
            </div>
            <a
              href="https://www.northxsouth.co"
              target="_blank"
              rel="noopener noreferrer"
              className={`inline-block text-sm px-4 py-2 leading-none border rounded text-primary-300 border-primary-300 hover:text-white hover:bg-primary-300 mt-4 ${breakpoint}:mt-0`}
            >
              Work With Me
            </a>
          </div>
        </div>
      </nav>
    </Headroom>
  )
}

export default Header
